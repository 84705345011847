/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/main.scss';

export const shouldUpdateScroll = ({ prevRouterProps, routerProps: { location } }) => {
  const { pathname } = location;

  if (!prevRouterProps || prevRouterProps.location.pathname !== pathname) {
    window.scrollTo(0, 0);
  }

  return false;
};
