import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";

import FooterDesktop from "./FooterDesktop";
import FooterMobile from "./FooterMobile";

import "./Footer.scss";

const FooterQuery = () => (
  <StaticQuery
    query={graphql`
      query footerQuery {
        drupal {
          menuByName(name: "footer") {
            links {
              label
              url {
                path
              }
              links {
                label
                url {
                  path
                }
                attribute(key: "class")
              }
            }
          }
        }
      }
    `}
    render={data => {
      const footerItems = data.drupal.menuByName.links;

      return (
        <>
          <nav className="footer">
            <Container>
              <FooterDesktop footerItems={footerItems} />
            </Container>
          </nav>
          <FooterMobile footerItems={footerItems} />
        </>
      );
    }}
  />
);

export default FooterQuery;
