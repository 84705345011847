/** @jsx jsx */
import { jsx } from 'theme-ui';

import { Link, Icon } from '../../common';

const Tag = ({ href, children }) => {
  return (
    <Link
      href={href}
      sx={{
        py: 2,
        pl: 2,
        pr: 3,
        bg: 'grey3',
        m: 'auto',
        borderRadius: 1,
        display: 'inline-block',
        textDecoration: 'none',
        '&:hover': { bg: 'grey4' },
      }}
    >
      <Icon name="tag" sx={{ mr: 2 }} />
      {children}
    </Link>
  );
};

export default Tag;
