import React from "react";
import Alert from "../Alert/Alert";
import * as moment from "moment";

const GdprAlert = () => {
  return (
    <Alert
      alertId="alert-gdpr"
      className="gdpr-alert slideInUp"
      dismissalExpiration={moment.utc().add(6, 'months')}
      variant="dark"
    >
      This site uses cookies to provide you with a great user experience. By using the TABC site, you accept our use of cookies.
    </Alert>
  );
};

export default GdprAlert;
