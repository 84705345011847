// Vendors
import React from "react";
import { motion } from "framer-motion";
import Col from "react-bootstrap/Col";

// Components
import { Icon, Link } from "../../common";
import TakeoverMenuColumns from "../TakeoverMenuColumns/TakeoverMenuColumns";

// Context
import { NavbarContext } from "../NavbarWrapper/Navbar/Navbar";

// Styles
import "./TakeoverMenu.scss";
import MainContainer from "../../common/Container/MainContainer/MainContainer";

const TakeoverMenu = ({ toggleDrawer }) => {
  return (
    <NavbarContext.Consumer>
      {context => {
        const { selectedMenuItems, dispatch } = context;
        return !!selectedMenuItems ? (
          <div className="takeover-menu-wrapper">
            <MainContainer row>
              <Col lg={3} className="takeover-menu-heading">
                <Link href={selectedMenuItems[0].url.path}>
                  <span className="column-title">
                    {selectedMenuItems[0].label}
                  </span>
                </Link>
              </Col>
              <Col lg={7} className="takeover-menu-items">
                <TakeoverMenuColumns
                  selectedMenuItems={selectedMenuItems}
                  dispatch={dispatch}
                />
              </Col>
              <Col lg={2} className="takeover-menu-exit">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => {
                    dispatch({ type: "UPDATE_SELECTED_MENU_ITEMS", payload: null });
                    toggleDrawer();
                  }}
                  title="Close"
                  aria-label="Close Menu"
                  className="btn btn-link"
                >
                  <Icon name="x" />
                </motion.button>
              </Col>
            </MainContainer>
          </div>
        ) : null;
      }}
    </NavbarContext.Consumer>
  );
};

export default TakeoverMenu;
