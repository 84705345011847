import React, { useRef, useState } from "react";
import { Button, Fade, Form, InputGroup } from "react-bootstrap";
import { Icon } from "../../common";
import SearchInput from "../Search/SearchInput";

export default ({ open, setShowSearchField, searchText, setSearchText, search, reset, handleSubmit }) => {
  const searchInput = useRef();
  const [entered, setEntered] = useState(false);

  return (
    <Form
      className="expandable-search expandable-search-desktop d-none d-md-flex justify-content-end align-items-center"
      onSubmit={handleSubmit}
    >
      <Fade
        className="expand"
        id="desktop-search-input"
        in={open}
        onEnter={() => setEntered(true)} // before fade in starts
        onEntered={() => searchInput.current.focus()} // after fade in completes
        onExited={() => setEntered(false)} // after fade out completes
        timeout={350}
      >
        <div>
          <InputGroup style={entered ? null : { display: 'none' }}>
            <SearchInput
              inputClassName="pr-6"
              ref={searchInput}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              onResetClick={() => reset()}
              size="sm"
            />
            <InputGroup.Append>
              <Button
                variant="primary"
                className="search-btn"
                disabled={!searchText}
                onClick={() => search(searchText)}
                size="sm"
              >
                Search
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
      </Fade>

      <Button
        variant="primary"
        onClick={() => setShowSearchField(!open)}
        className="search-icon-btn"
        aria-label={open ? 'Close Search' : 'Open Search'}
        title={open ? 'Close' : 'Search'}
        aria-controls="desktop-search-input"
        aria-expanded={open}
      >
        {open ? <Icon name="x" /> : <Icon name="search" />}
      </Button>
    </Form>
  );
};
