import React from "react";
import { Text as RebassText } from "rebass";

const Text = props => {
  return (
    <RebassText
      as={props.as || "p"}
      fontWeight="body"
      fontSize={props.size === "large" ? [4, 5] : [3, 4]}
      color={props.invert ? "white" : "text"}
      mY={props.mY || 3}
      {...props}
    />
  );
};

export default Text;