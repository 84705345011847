import React from "react";
import classnames from "classnames";

import "./icon.scss";

import alertDanger from "../../assets/icons/alert-danger.svg";
import advisory from "../../assets/icons/advisory.svg";
import agencyInitiative from "../../assets/icons/agency-initiative.svg";
import alertInfo from "../../assets/icons/alert-info.svg";
import alertSuccess from "../../assets/icons/alert-success.svg";
import alertWarning from "../../assets/icons/alert-warning.svg";
import announcement from "../../assets/icons/announcement.svg";
import arrowRight from "../../assets/icons/arrow-right.svg";
import article from "../../assets/icons/article.svg";
import bell from "../../assets/icons/bell.svg";
import bulletin from "../../assets/icons/bulletin.svg";
import check from "../../assets/icons/check.svg";
import chevronDown from "../../assets/icons/chevron-down.svg";
import chevronLeft from "../../assets/icons/chevron-left.svg";
import chevronLeftSkipFirst from "../../assets/icons/chevron-left-skip-first.svg";
import chevronRight from "../../assets/icons/chevron-right.svg";
import chevronRightSkipLast from "../../assets/icons/chevron-right-skip-last.svg";
import chevronUp from "../../assets/icons/chevron-up.svg";
import creditCard from "../../assets/icons/credit-card.svg";
import download from "../../assets/icons/download.svg";
import edit from "../../assets/icons/edit.svg";
import exclamation from "../../assets/icons/exclamation.svg";
import externalLink from "../../assets/icons/external-link.svg";
import eye from "../../assets/icons/eye.svg";
import eyeClosed from "../../assets/icons/eye-closed.svg";
import facebook from "../../assets/icons/facebook.svg";
import facebookSquare from "../../assets/icons/facebook-square.svg";
import hamburger from "../../assets/icons/hamburger.svg";
import industryBrief from "../../assets/icons/industry-brief.svg";
import info from "../../assets/icons/info.svg";
import link from "../../assets/icons/link.svg";
import list from "../../assets/icons/list.svg";
import lock from "../../assets/icons/lock.svg";
import mail from "../../assets/icons/mail.svg";
import mapPin from "../../assets/icons/map-pin.svg";
import messageSquare from "../../assets/icons/message-square.svg";
import minus from "../../assets/icons/minus.svg";
import more from "../../assets/icons/more.svg";
import newsRelease from "../../assets/icons/news-release.svg";
import phone from "../../assets/icons/phone.svg";
import play from "../../assets/icons/play.svg";
import plus from "../../assets/icons/plus.svg";
import plusCircle from "../../assets/icons/plus-circle.svg";
import printer from "../../assets/icons/printer.svg";
import repeat from "../../assets/icons/repeat.svg";
import search from "../../assets/icons/search.svg";
import shield from "../../assets/icons/shield.svg";
import star from "../../assets/icons/star.svg";
import tag from "../../assets/icons/tag.svg";
import twitter from "../../assets/icons/twitter.svg";
import twitterSquare from "../../assets/icons/twitter-square.svg";
import user from "../../assets/icons/user.svg";
import wallet from "../../assets/icons/wallet.svg";
import x from "../../assets/icons/x.svg";
import youtube from "../../assets/icons/youtube.svg";
import youtubeSquare from "../../assets/icons/youtube-square.svg";

export const icons = {
  "alert-danger": alertDanger,
  "alert-info": alertInfo,
  "alert-success": alertSuccess,
  "alert-warning": alertWarning,
  advisory,
  "agency-initiative": agencyInitiative,
  announcement,
  "arrow-right": arrowRight,
  article,
  bell,
  bulletin,
  check,
  "chevron-down": chevronDown,
  "chevron-left": chevronLeft,
  "chevron-left-skip-first": chevronLeftSkipFirst,
  "chevron-right": chevronRight,
  "chevron-right-skip-last": chevronRightSkipLast,
  "chevron-up": chevronUp,
  "credit-card": creditCard,
  download,
  edit,
  exclamation,
  "external-link": externalLink,
  eye,
  "eye-closed": eyeClosed,
  facebook,
  "facebook-square": facebookSquare,
  hamburger,
  "industry-brief": industryBrief,
  info,
  link,
  list,
  lock,
  "map-pin": mapPin,
  mail,
  "message-square": messageSquare,
  minus,
  more,
  "news-release": newsRelease,
  phone,
  play,
  plus,
  "plus-circle": plusCircle,
  printer,
  repeat,
  search,
  shield,
  star,
  tag,
  twitter,
  "twitter-square": twitterSquare,
  user,
  wallet,
  x,
  youtube,
  "youtube-square": youtubeSquare
};

const Icon = ({ name, className, srLabel, ...props }) => {
  if (!name || !icons[name]) return null;

  const Icon = icons[name];

  return (
    <span {...props} className={classnames(`icon icon-${name}`, className)}>
      <Icon role="presentation" />
      {srLabel && <span className="sr-only">{srLabel}</span>}
    </span>
  );
};

export default Icon;
