import { navigate } from '@reach/router';

const internalRegex = /^https?:\/\/[www.]*tabc.texas\.gov$/;

export const isInternalUrl = (url) => {
  const externalDev = /^(https?|ftp):\/\//i.test(url) || /^mailto:/i.test(url);

  return process.env.NODE_ENV === 'production' ? !externalDev || url.match(internalRegex) : !externalDev;
};

export const formatInternalUrl = (url) =>
  url === '/' || url.endsWith('/') || /\.[a-zA-Z0-9]+$/.test(url) || url.includes('?') ? url : `${url}/`;

export const updateFilterParams = (filters) => {
  const formattedFilters = Object.entries(filters).flatMap(([filter, values]) =>
    Object.entries(values).map((v) => [...v, filter])
  );

  const { params } = formattedFilters.reduce(
    (acc, [key, value, filter]) => {
      if (value) {
        acc.params.append(filter.toLowerCase(), key);
      }

      return acc;
    },
    { params: new URLSearchParams() }
  );

  const paramString = params.toString();
  const route = paramString.length ? `?${paramString}` : './';

  navigate(route);
};

export const resetPagination = () => {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.get('page')) {
    let paramString, route;

    urlParams.delete('page');

    paramString = urlParams.toString();
    route = paramString.length ? `?${paramString}` : './';

    window.history.pushState(null, null, route);
  }
};

export const getGoogleMapsUrl = (address) => {
  const { addressLine1, addressLine2, locality, postalCode } = address;
  const queryParam = new URLSearchParams();
  const queryString = `${addressLine1}, ${addressLine2 && addressLine2 + ', '}${locality}, TX ${postalCode}`;

  queryParam.set('query', queryString);

  return `https://www.google.com/maps/search/?api=1&${queryParam.toString()}`;
};
