import React from 'react';
import Img from 'gatsby-image';
import Image from 'react-bootstrap/Image';

// renders gatsby optimized image if it exists, otherwise renders image from localURL
const CommonImage = ({
  entity: {
    fieldMediaImage: { alt, width, drupalURL, url },
  },
  files,
}) => {
  const filterNull = files.filter((file) => file.url !== null);
  const { childImageSharp, localURL } = filterNull.find((el) => {
    return url ? el.url === url : drupalURL ? el.url === drupalURL : el;
  });

  return childImageSharp ? (
    // optimized image, don't allow to bigger than natural size
    <div style={{ width: '100%', maxWidth: width }}>
      <Img fluid={childImageSharp.fluid} alt={alt} />
    </div>
  ) : (
    // svgs won't have an optimized image and can be any width
    <Image src={encodeURI(localURL)} alt={alt} fluid />
  );
};
export default CommonImage;
