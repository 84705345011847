/** @jsx jsx */
import { jsx } from 'theme-ui';
import Col from 'react-bootstrap/Col';
import { styleVars } from '../../../lib/helpers/style';

let breakpointProps = {
  lg: { span: 8, offset: 2 },
};
breakpointProps[styleVars.mobileBreakpoint] = { span: 3, offset: 0 };

const LeftContainer = ({ className = '', id = '', maxWidth = '', children, wrap, ...rest }) => (
  <Col id={id} className={`${className} order-1`} {...breakpointProps} {...rest}>
    {children}
  </Col>
);

export default LeftContainer;
