import React from "react";
import { Button as RebassButton } from "rebass";

const Button = props => {
  if (props.as === "button" && props.styledAs === "a") {
    return (
      <RebassButton
        fontSize={[4]}
        color={props.color ? props.color : 'blue'}
        sx={{
          variant: "links.inverse",
          textDecoration: 'underline',
          fontWeight: 'normal'
        }}
        {...props}
      />
    );
  } else {
    return (
      <RebassButton
        as={props.as}
        fontSize={[4]}
        {...props}
      />
    );
  }
};

export default Button;
