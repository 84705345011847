import React from 'react';
import Col from 'react-bootstrap/Col';
import { Link } from '../../common';

const linkItem = ({ url: { path }, label }, i) => (
  <li key={i}>
    <Link href={path}>{label}</Link>
  </li>
);

const halfLength = (arr) => Math.ceil(arr.length / 2);

const FooterColumn = ({
  section: {
    label,
    links,
    url: { path },
  },
  dualCol,
}) => {
  const menuItems = links.filter(({ attribute }) => attribute !== 'mobile_only');

  return dualCol ? (
    <>
      <Col md={3}>
        <p className="section-title">{path ? <Link href={path}>{label}</Link> : label}</p>
        <ul className="list-unstyled">{menuItems.slice(0, halfLength(menuItems)).map(linkItem)}</ul>
      </Col>
      <Col md={3}>
        <p className="section-title">&nbsp;</p>
        <ul className="list-unstyled">{menuItems.slice(halfLength(menuItems), menuItems.length).map(linkItem)}</ul>
      </Col>
    </>
  ) : (
    <Col md={3}>
      <p className="section-title">{path ? <Link href={path}>{label}</Link> : label}</p>
      <ul className="list-unstyled">{menuItems.map(linkItem)}</ul>
    </Col>
  );
};

export default FooterColumn;
