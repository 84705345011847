/** @jsx jsx */
// Vendors
import { jsx } from '@emotion/core';
import { createContext, useState, useRef, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { motion } from 'framer-motion';
import { Container, Image, Row, Col, Fade } from 'react-bootstrap';

import { GlobalContext } from '../../../components/Layout';

// Components
import { Icon, Link } from '../../../common';
import ExpandableSearch from '../../ExpandableSearch/ExpandableSearch';
import NavMenuItem from '../../NavMenuItem/NavMenuItem';
import TakeoverMenu from '../../TakeoverMenu/TakeoverMenu';
import SubNavMobile from '../../SubNavMobile/SubNavMobile';
import { DrawerOpen, DrawerToggle } from '../../Drawer/Drawer';

// Helpers
import { useWindowSize } from '../../../hooks/useWindowSize';
import { breakpoints } from '../../../lib/helpers/style';

// Styles
import './Navbar.scss';
import LogoSm from '../../../assets/logo/tabc-badge-sm@2x.png';
import LogoText from '../../../assets/logo/tabc.svg';

export const NavbarContext = createContext();

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query navbarQuery {
      drupal {
        menuByName(name: "main") {
          links {
            links {
              description
              expanded
            }
            label
            url {
              path
            }
            ... on Drupal_MenuLink {
              links {
                links {
                  description
                  expanded
                  label
                  url {
                    path
                    routed
                  }
                  links {
                    description
                    expanded
                    label
                    url {
                      path
                      routed
                    }
                  }
                }
                label
                url {
                  path
                }
              }
            }
          }
        }
      }
    }
    #
  `);

  const navItems = data.drupal.menuByName.links[0].links;

  // State
  const containerRef = useRef(null);
  const [showSearchField, setShowSearchField] = useState(false);
  const { navDrawerOpen, selectedMenuItems, dispatch } = useContext(GlobalContext);

  const size = useWindowSize();
  const openTakeoverMenu = size.width >= breakpoints.Nav && !!selectedMenuItems;

  // Animation
  const variants = {
    hidden: {
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
    },
    show: {
      display: 'flex',
      opacity: 1,
      transition: {
        delayChildren: 0.05,
        staggerChildren: 0.075,
      },
    },
  };

  const hideLogo = size.width < breakpoints.Md && showSearchField;

  const renderHiddenLinks = (links) => {
    return (
      <ul>
        {links.map((link) => (
          <li key={link.label}>
            <Link href={link.url.path}>{link.label}</Link>
            {link.links?.length > 0 && renderHiddenLinks(link.links)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <NavbarContext.Provider
      value={{
        selectedMenuItems,
        dispatch,
        navItems,
      }}
    >
      <nav className="nav-global">
        <Container>
          <Row className="position-relative">
            <Fade in={!hideLogo} className={hideLogo ? 'position-absolute h-100' : ''}>
              <Col xs={7} md={2} className="d-flex align-items-center">
                <Link href="/" className="logo text-nowrap">
                  <Image src={LogoSm} alt="Texas Alcoholic Beverage Commission Logo" width="46" className="mr-3" />
                  <Image src={LogoText} alt="Texas Alcoholic Beverage Commission" width="62" height="17" />
                </Link>
              </Col>
            </Fade>
            <Col xs={hideLogo ? 12 : 5} md={hideLogo ? 12 : 10} className="d-flex align-items-center">
              <div className="nav-middle">
                <motion.ul
                  variants={variants}
                  animate={showSearchField ? 'hidden' : 'show'}
                  style={{justifyContent : 'flex-start'}}
                  className="nav-middle-list"
                  ref={containerRef}
                >
                  {navItems.map((menuItem, i) => (
                    <DrawerToggle
                      key={i}
                      render={(toggle) => <NavMenuItem menuItem={menuItem} toggle={toggle} />}
                    ></DrawerToggle>
                  ))}
                </motion.ul>
                <ExpandableSearch open={showSearchField} setShowSearchField={setShowSearchField} />
              </div>

              <DrawerToggle
                render={(toggle) => (
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="hamburger-menu-button"
                    tabIndex="0"
                    aria-label={navDrawerOpen ? 'Close Menu' : 'Open Menu'}
                    aria-expanded={navDrawerOpen}
                    aria-controls="nav-drawer"
                    onClick={() => {
                      if (!!selectedMenuItems) {
                        dispatch({ type: 'UPDATE_SELECTED_MENU_ITEMS', payload: null });
                      }
                      toggle();
                    }}
                  >
                    <Icon name="hamburger" className="hamburger-menu button-content" tabIndex="-1" />
                  </motion.button>
                )}
              ></DrawerToggle>
            </Col>
          </Row>
        </Container>
      </nav>
      <DrawerOpen
        forceOpen={!!selectedMenuItems}
        render={(toggle) => {
          if (openTakeoverMenu) {
            return <TakeoverMenu toggleDrawer={toggle} />;
          } else {
            return <SubNavMobile />;
          }
        }}
      ></DrawerOpen>

      {/* hidden nav links for SEO */}
      <noscript>
        <nav>{renderHiddenLinks(navItems)}</nav>
      </noscript>
    </NavbarContext.Provider>
  );
};

export default Navbar;
