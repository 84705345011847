import React from "react";
import { convertNodeToElement } from "react-html-parser";

import { Link } from "../../common";

export const transformHtmlWithLink = node => {
  if (node.type === "tag" && node.name === "a") {
    return (
      <Link href={node.attribs.href} externalIcon={false}>
        {node.children.map((child, index) => convertNodeToElement(child, index))}
      </Link>
    );
  }
};

export const mergeFilters = (filters, payload) => ({
  ...filters,
  [payload.type]: {
    ...filters[payload.type],
    [payload.key]: payload.value
  }
});