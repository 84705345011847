const geocoder = window?.google ? new window.google.maps.Geocoder() : {},
  googlePostalCode = 'postal_code';

export const getLocation = () =>
  new Promise((res, rej) => {
    const defaultSettings = { enableHighAccuracy: false, timeout: 10000, maximumAge: 0 },
      geo = navigator.geolocation;

    if (!geo) rej();

    return geo.getCurrentPosition(res, rej, defaultSettings);
  });

export const getZip = async (latLng) =>
  new Promise((res, rej) =>
    geocoder.geocode({ latLng }, ([{ address_components }]) => {
      const { short_name: zip } = address_components.find(({ types: [firstType] }) => firstType === googlePostalCode);
      res(zip);
    })
  );

export const getCoordsFromAddress = (address) =>
  new Promise((res, rej) =>
    geocoder.geocode({ address }, (first) => {
      if (!first[0]) return rej(true);

      const [
        {
          geometry: { location },
        },
      ] = first;
      res({ latitude: location.lat(), longitude: location.lng() });
    })
  );

export const getCoordsFromPosition = (latitude, longitude) => new window.google.maps.LatLng(latitude, longitude);

export const metersToMiles = (meters) => Math.ceil(meters * 0.000621371192);
