import React from "react";
import { Button, FormControl, Form } from "react-bootstrap";
import { Icon } from "../../common";

const SearchInput = React.forwardRef(({ inputClassName = "", onChange, onResetClick, size, value, ...rest }, ref) => {
  const handleResetClick = (e) => {
    onResetClick(e);
    ref.current.focus();
  }

  return (
    <div className="search-input-wrapper form-control-wrapper" {...rest}>
      <Form.Label srOnly>
        global search
      </Form.Label>
      <FormControl
        aria-label="Search"
        className={`search-input font-weight-bold ${inputClassName}`}
        placeholder="I'm looking for..."
        size={size}
        type="search"
        value={value}
        ref={ref}
        onChange={onChange}
      />
      <Button
        aria-label="Clear"
        disabled={!value}
        onClick={handleResetClick}
        size={size}
        type="reset"
        variant="clear"
      >
        <Icon name="x" />
      </Button>
    </div>
  );
});

export default SearchInput;
