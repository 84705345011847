import React from "react";
import Row from "react-bootstrap/Row";
import FooterColumn from "./FooterColumn";

const FooterDesktop = ({ footerItems }) => (
  <Row className="footer-body">
    {footerItems.map((section, i) => (
      <FooterColumn section={section} key={i} dualCol={i===0} />
    ))}
  </Row>
);

export default FooterDesktop;
