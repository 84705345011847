import React, { Fragment, useState, useEffect } from 'react';
import { useContext } from 'react';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';

import { Icon } from '..';
import { isInternalUrl, formatInternalUrl } from '../../lib/helpers';

import { GlobalContext } from '../../components/Layout';
import XIcon from '../../assets/icons/x.svg';
import Logo from '../../assets/logo/tabc-badge-lg.png';
import DotLoader from '../DotLoader/DotLoader';
const isBrowser = typeof window !== `undefined`;


const useExternalModalEntities = () => {
 const queryData = useStaticQuery(graphql`
    query MyQuery {
      drupal {
        taxonomyTermQuery(filter: {groups: {}, conditions: {field: "vid", operator: EQUAL, value: "redirect_modal"}}) {
          entities{
            ... on Drupal_TaxonomyTermRedirectModal {
            name
            description {
              value
              format
              processed
            }
          }
          }
        }
      }
    }
  `);
  return queryData?.drupal?.taxonomyTermQuery?.entities;
};

const Link = ({ href, disabled, externalIcon = true, downloadIcon, children, onClick, style = {}, ...rest }) => {
  let timeout;
  const [showModal, setShowModal] = useState(false);
  const { dispatch } = useContext(GlobalContext);
  const externalEntities = useExternalModalEntities();
  useEffect(() => {
    if(showModal) {
      timeout = setTimeout(() => {
        setShowModal(false)
      }, 5000)
    } else {
      clearTimeout(timeout)
    }
    return () => clearTimeout(timeout);
  }, [showModal])
  const selectedEntity = externalEntities?.find((entity) => href.includes(entity.name))
  const resetNavDrawer = () => {
    dispatch({ type: 'UPDATE_SELECTED_MENU_ITEMS', payload: null });
    dispatch({ type: 'UPDATE_NAV_DRAWER', payload: false });
  };

  const LinkIcon = (props) => <Icon className="ml-2" style={{ fontSize: '1em' }} {...props} />;

  if (href && isInternalUrl(href) && !downloadIcon) {
    const formattedHref = formatInternalUrl(href);

    let modifiedOnClick = resetNavDrawer;

    if (onClick) {
      modifiedOnClick = () => {
        onClick();
        resetNavDrawer();
      };
    }

    return (
      <GatsbyLink to={formattedHref} onClick={modifiedOnClick} style={{ ...style }} {...rest}>
        {children}
      </GatsbyLink>
    );
  }
  const handleLinkClick = (evt) => {
    evt.preventDefault();
    if(selectedEntity) {
      setShowModal(true)
      setTimeout(() => {
        window.open(href, "_blank")
      },1000)
    } else {
      window.open(href, "_blank")
    }
  }
  return (
    <Fragment>
      {showModal && isBrowser && (
        <div className="modal-bookmark-wrapper">
          <div className="modal-bookmark">
            <div
              onClick={() => setShowModal(false)}
              onKeyPress={() => setShowModal(false)}
              className="modal-bookmark-close"
              tabIndex="0"
            >
              <XIcon /> <span className="sr-only">Close Modal</span>
            </div>
            <img className="mt-8" src={Logo} alt="TABC Logo" />
            <div className="modal-bookmark-content">
              <h3 className="text-gold mt-8" dangerouslySetInnerHTML={{ __html:  `${selectedEntity?.description?.value}`}}></h3>
              <DotLoader />
            </div>
          </div>
        </div>
      )}
      <a
        href={""}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          ...style,
        }}
        onClick={handleLinkClick}
        //target="_blank"
        //rel="noopener noreferrer"
        {...rest}
      >
        <span>{children}</span>
        {externalIcon && !downloadIcon && <LinkIcon srLabel="(external link)" name="external-link" />}
        {downloadIcon && <LinkIcon srLabel="(download document)" name="download" />}
      </a>
    </Fragment>
  );
};

export default Link;
