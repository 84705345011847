import React from 'react';
import { motion } from 'framer-motion';
import Button from 'react-bootstrap/Button';

// Components
import { Icon, Link } from '../../common';

// Context
import { NavbarContext } from '../NavbarWrapper/Navbar/Navbar';

const NavMenuItem = ({ menuItem, showCaret = false, toggle }) => {
  const variants = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };
  return (
    <NavbarContext.Consumer>
      {(context) => {
        const { selectedMenuItems, dispatch } = context;
        const itemIsActive = !selectedMenuItems ? false : menuItem === selectedMenuItems[0];
        const hasLinks = menuItem.links && menuItem.links.length;

        return (
          <motion.li initial={false} variants={variants}>
            {!hasLinks ? (
              <Link href={menuItem.url.path}>{menuItem.label}</Link>
            ) : (
              <Button
                aria-expanded={itemIsActive}
                aria-controls="nav-drawer"
                className={`nav-toggle${itemIsActive ? ' active' : ''}`}
                href={'#'}
                variant="link"
                onClick={(e) => {
                  const newSelection = itemIsActive ? null : [menuItem];

                  e.preventDefault();
                  e.target.blur();
                  dispatch({ type: 'UPDATE_SELECTED_MENU_ITEMS', payload: newSelection });
                  toggle();
                }}
              >
                {menuItem.label}
                {hasLinks && showCaret && <Icon name="chevron-right" />}
              </Button>
            )}
          </motion.li>
        );
      }}
    </NavbarContext.Consumer>
  );
};

export default NavMenuItem;
