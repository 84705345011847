import React, { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import useMounted from "../../hooks/useMounted";
import { MainContainer, Icon } from "../../common";
import * as moment from "moment";

// custom hook
const useStorage = (alertId, dismissalExpiration, currentTime) => {
  let initialState = false;

  if (typeof window !== 'undefined') {
    const storedAlert = JSON.parse(window.localStorage.getItem(alertId));

    if (storedAlert) {
      initialState = storedAlert.dismissalExpiration !== null && currentTime.isAfter(storedAlert.dismissalExpiration);
    } else {
      initialState = true;
    }
  }

  const [showAlert, setShow] = useState(initialState);

  const closeAlert = () => {
    setShow(false);
    window.localStorage.setItem(alertId, JSON.stringify({ dismissalExpiration }));
  }

  return [showAlert, closeAlert];
}

const CustomAlert = ({
  alertId,
  children,
  className = "",
  currentTime = moment.utc(),
  dismissalExpiration,
  variant
}) => {
  const [showAlert, closeAlert] = useStorage(alertId, dismissalExpiration, currentTime);
  const hasMounted = useMounted();

  if (!hasMounted) {
    return null;
  }

  return (
    <Alert
      show={showAlert}
      className={`px-0 ${className}`}
      variant={variant}
    >
      <MainContainer className="justify-content-between align-items-start">
        <p className="m-0 flex-md-grow-1">{children}</p>
        
        <Button
          variant="icon"
          onClick={() => closeAlert()}
          className="close text-reset flex-shrink-0 ml-2"
          title="Dismiss"
          aria-label="Dismiss"
        >
          <Icon name="x" />
        </Button>
      </MainContainer>
    </Alert>
  );
};

export default CustomAlert;
