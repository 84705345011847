import vars from '../../styles/_variables.module.scss';
export const styleVars = vars;

export const breakpoints = getBreakpoints(vars);

// get page breakpoint values from sass vars
function getBreakpoints(vars) {
  let breakpoints = {};

  // remove 'px' from breakpoint vars and return as number
  Object.keys(vars)
    .filter(key => key.startsWith('breakpoint'))
    .map(key => {
      breakpoints[key.replace('breakpoint', '')] = parseInt(vars[key].slice(0, -2));
      return breakpoints;
    });
  
  return breakpoints;
}
