import React from 'react';
import { Flex } from 'rebass';
import { Text } from '../../common';

const CircleLoader = ({ text, className }) => {
  return (
    <Flex className={className}>
      <div className="circle-loader" />
      {text && (
        <Text color="white" sx={{ marginLeft: '1rem' }}>
          {text}
        </Text>
      )}
    </Flex>
  );
};

export default CircleLoader;
