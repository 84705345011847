import vars from '../styles/_variables.module.scss';

export default {
  useBodyStyles: false,
  colors: {
    text: vars.bodyColor,
    darkestBlue: vars.stratos,
    darkBlue: vars.darkBlue,
    blue: vars.blue,
    yellow: vars.yellow,
    darkYellow: vars.gold,
    grey6: vars.gray600,
    grey5: vars.gray600,
    grey4: vars.gray300,
    grey3: vars.gray200,
    grey2: vars.gray100,
    grey1: vars.white,
    secondaryBlue: vars.persianBlue,
    secondaryRed: vars.red,
    black: vars.bodyColor,
  },
  buttons: {
    base: {
      padding: vars.buttonPadding,

      borderWidth: '2px',
      borderRadius: '40px',
      borderColor: 'blue',

      '&:hover': {
        cursor: 'pointer',
        borderWidth: 'normal',
      },
    },
    primary: {
      color: 'white',
      bg: 'blue',
      padding: vars.buttonPadding,

      borderWidth: '2px',
      borderRadius: '40px',
      borderColor: 'blue',

      '&:hover': {
        backgroundColor: '#2944d7',
        cursor: 'pointer',
        color: 'white',
      },
      '&:disabled': {
        opacity: '44%',
        cursor: 'pointer',
      },
    },
    primaryInverse: {
      color: 'darkestBlue',
      bg: 'white',
      padding: vars.buttonPadding,

      borderWidth: '2px',
      borderRadius: '40px',
      borderColor: 'white',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    secondary: {
      color: 'blue',
      bg: 'blue',
      padding: vars.buttonPadding,

      borderColor: 'blue',
      borderRadius: '40px',
      borderWidth: '2px',
    },
    secondaryInverse: {
      color: 'white',
      bg: 'transparent',
      padding: vars.buttonPadding,

      borderColor: 'white',
      borderRadius: '40px',
      borderWidth: '2px',
    },
  },
  fonts: {
    body: vars.fontFamily,
    button: vars.fontFamily,
    a: vars.fontFamily,
    heading: vars.fontFamily,
    monospace: vars.fontMonospace,
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  links: {
    primary: {
      color: 'blue',
      textDecoration: 'underline',
      fontWeight: 'body',
    },
    inverse: {
      color: 'white',
      textDecoration: 'underline',
      fontWeight: 'body',
      backgroundColor: 'transparent',
      padding: 0,

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: 'inherit',
      },
    },
  },
  //           0,  1,  2,  3,  4,  5,  6,  7,  8,  9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21
  fontSizes: [11, 13, 14, 16, 18, 20, 22, 24, 26, 29, 30, 31, 32, 34, 35, 36, 37, 38, 40, 46, 48, 56],
  //      0, 1, 2,  3,  4,  5,  6,  7,  8,  9, 10, 11, 12,  13,  14,  15,  16,  17,  18
  space: [0, 4, 8, 16, 32, 40, 48, 56, 64, 72, 80, 88, 96, 104, 112, 120, 128, 256, 512],
  //      0, 1, 2,  3,  4,  5,  6,  7
  radii: [0, 4, 8, 16, 32, 40, 48, 56],
  breakpoints: [vars.breakpointMd, vars.breakpointLg, vars.breakpointXl, vars.breakpointXxl],
};
