/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { useContext, useState, useEffect } from 'react';
import { Flex, Box } from 'rebass';
import { GlobalContext } from '../../Layout';
import { Button, Icon, Text, MainContainer } from '../../../common';
import LocationBarForm from './LocationBarForm';
import CircleLoader from '../../Loader/CircleLoader';

const locationDefaultText = 'Enter your location',
  locationChangeText = 'Change';

const LocationBar = ({ className = '', id = '', children, ...rest }) => {
  const {
      getUserLocation,
      recommendedLocations: { regional },
    } = useContext(GlobalContext),
    [showInput, setShowInput] = useState(false),
    [loading, updateLoading] = useState(false),
    [clickLoading, updateClickLoading] = useState(false),
    [userLocationError, setUserLocationError] = useState(false);

  const handleLocationIconClick = async (e) => {
    e.currentTarget.blur();

    updateClickLoading(true);
    const userLocation = await getUserLocation();
    updateClickLoading(false);

    if (userLocation.error) {
      setShowInput(true);
      setUserLocationError(true);
    } else {
      setUserLocationError(false);
    }
  };

  const retrieveLocation = async () => {
    updateLoading(true);
    await getUserLocation();
    updateLoading(false);
  };

  useEffect(() => {
    retrieveLocation();
  }, []);

  return (
    <div {...rest} className="location-banner">
      <Flex bg="blue" height="56px">
        <MainContainer className="align-items-center text-white py-2" {...rest} id={id}>
          <Box mx="auto" />
          {loading ? (
            <CircleLoader text={'Retrieving Local TABC...'} />
          ) : (
            <>
              <span className="location-bar__title">
                <Text
                  fontSize={['1rem', '1.2rem']}
                  as="p"
                  styledAs="p"
                  color="white"
                  fontWeight="bold"
                  sx={{ marginX: '1.5rem' }}
                >
                  Your local TABC
                </Text>
              </span>
              <div className={`location-bar__expandable`}>
                {!showInput && (
                  <span className="fadeInRight" style={{ display: 'flex' }}>
                    {!!regional.office && (
                      <span>
                        <Text fontSize={['1rem', '1.2rem']} styledAs="p" color="white" sx={{ marginRight: '1rem' }}>
                          {regional.office.title}
                        </Text>
                      </span>
                    )}
                    <span>
                      <Button
                        onClick={() => setShowInput(!showInput)}
                        fontSize={['1rem', '1.2rem']}
                        as="button"
                        styledAs="a"
                        variant="primary"
                        color="white"
                        className="enter-location"
                      >
                        {!!regional.office ? locationChangeText : locationDefaultText}
                      </Button>
                    </span>
                  </span>
                )}
                {showInput && (
                  <LocationBarForm
                    setShowInput={setShowInput}
                    showInput={showInput}
                    userLocationError={userLocationError}
                    clearUserLocationError={() => setUserLocationError(false)}
                  />
                )}
              </div>
              {clickLoading ? (
                <CircleLoader className="ml-3" />
              ) : (
                <Button
                  onClick={handleLocationIconClick}
                  className="location-bar__icon ml-3"
                  as="button"
                  styledAs="a"
                  color="white"
                  title="Use your current location"
                  aria-label="Use your current location"
                >
                  <Icon name="map-pin" sx={{ margin: 'auto', fontSize: '1.8rem' }} />
                </Button>
              )}
            </>
          )}
        </MainContainer>
      </Flex>
    </div>
  );
};

export default LocationBar;
