import React from 'react';
import BackgroundImage from 'gatsby-background-image';

const SquareImage = ({
  entity: {
    fieldMediaImage: { alt, width, drupalURL },
  },
  files,
}) => {
  const { childImageSharp, localURL } = files.find(({ url }) => url === drupalURL);
  return childImageSharp ? (
    // optimized image
    <BackgroundImage aria-label={alt} className="square-img" fluid={childImageSharp.fluid} role="img" />
  ) : (
    <div
      aria-label={alt}
      className="square-img"
      role="img"
      style={{ backgroundImage: `url(${encodeURI(localURL)})` }}
    />
  );
};
export default SquareImage;
