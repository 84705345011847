import React, { useState } from 'react';
import { Accordion as BootstrapAccordion, Button, Card, Container } from 'react-bootstrap';
import ConditionalWrapper from '../../components/Helpers/ConditionalWrapper';
import { usePDFReplace } from '../../hooks/use-pdf-replace';
import ReactHtmlParser from 'react-html-parser';
import { Text } from '../../common';

const Accordion = ({ accordionItems, className, container, type, defaultActiveItemIndex = 0 }) => {
  const [panelIndex, setPanelIndex] = useState();
  const defaultKey = defaultActiveItemIndex ? `accordionitem-${defaultActiveItemIndex}` : null;

  return (
    <BootstrapAccordion defaultActiveKey={defaultKey} className={className}>
      {accordionItems.map(({ header, content }, i) => {
        const key = `accordionitem-${i}`;
        const collapseClass = key === panelIndex ? 'open' : 'collapsed'; // this class is used for animating +/-

        return (
          <Card key={key} className="accordion-card">
            <ConditionalWrapper condition={container} wrapper={(children) => <Container>{children}</Container>}>
              <>
                <Card.Header className={collapseClass}>
                  <BootstrapAccordion.Toggle
                    as={Button}
                    eventKey={key}
                    variant="link"
                    className="toggle-btn w-100 p-0"
                    onClick={() => setPanelIndex(key === panelIndex ? null : key)}
                    aria-expanded={key === panelIndex}
                    aria-controls={key}
                    tabIndex="0"
                  >
                    <div className="button-content d-flex justify-content-between align-items-start" tabIndex="-1">
                      <span className="accordion-header">{header}</span>
                      <span className="plus-minus-toggle" aria-hidden="true" />
                    </div>
                  </BootstrapAccordion.Toggle>
                </Card.Header>
                {type === 'footer' && (
                  <BootstrapAccordion.Collapse eventKey={key} id={key}>
                    <Card.Body>{content}</Card.Body>
                  </BootstrapAccordion.Collapse>
                )}
                {type !== 'footer' && (
                  <BootstrapAccordion.Collapse eventKey={key} id={key}>
                    <AccordionBody content={content} />
                  </BootstrapAccordion.Collapse>
                )}
              </>
            </ConditionalWrapper>
          </Card>
        );
      })}
    </BootstrapAccordion>
  );
};

export default Accordion;

const AccordionBody = (content) => {
  const sanitizedPDF = usePDFReplace(content.content ? content.content : null);
  return <Text as="div">{ReactHtmlParser(sanitizedPDF)}</Text>;
};
