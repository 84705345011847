import React, { useRef, useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { Icon } from "../../common";
import SearchInput from "../Search/SearchInput";

export default ({ open, setShowSearchField, searchText, setSearchText, search, reset, handleSubmit }) => {
  const searchInput = useRef();
  const [entered, setEntered] = useState(false);

  const handleSearchClick = () => {
    if (open) {
      search(searchText);
    } else {
      // open search box
      setShowSearchField(true);
    }
  };

  useEffect(() => {
    // set entered state when animation is complete on exiting but at beginning of animation on entering
    const timer = setTimeout(() => {
      setEntered(open);
    open && searchInput.current.focus(); // focus on search input after opening
    }, open ? 1 : 350);
    return () => clearTimeout(timer);
  }, [open]);

  return (
    <Form
      className="expandable-search expandable-search-mobile d-md-none"
      onSubmit={handleSubmit}
    >
      <Form.Group
        className={`expand` + (open ? ' open' : '')}
        controlId="header-search-form"
      >
        <SearchInput
          ref={searchInput}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
          onResetClick={() => reset()}
          size="sm"
          style={entered ? null : { display: 'none' }}
        />
        <Button
          variant="primary"
          className="search-icon-btn"
          disabled={(open && !searchText)}
          onClick={handleSearchClick}
          aria-label="Search"
          size="sm"
        >
          <Icon name="search" />
        </Button>
      </Form.Group>
    </Form>
  );
};
