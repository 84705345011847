import React from 'react';
import { Button } from 'react-bootstrap';

const Submit = ({ status = 'new', disabled, children, checkmark = null, showTextWhileLoading = false, ...rest }) => {
  return (
    <div className="submit-wrapper">
      <Button {...rest} disabled={disabled || status !== 'new'} type="submit">
        {status !== 'new' ? (
          <div>
            <div className={`circle-loader mr-3 ${status === 'submitted' ? 'load-complete' : ''}`}>
              {checkmark && <div className="circle-loader__checkmark draw"></div>}
            </div>
            {showTextWhileLoading && children}
          </div>
        ) : (
          children
        )}
      </Button>
    </div>
  );
};

export default Submit;
