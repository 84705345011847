/** @jsx jsx */
import { jsx } from "theme-ui";
import ReactHtmlParser from "react-html-parser";
import * as moment from "moment";

import { Link } from "../../../common";
import Alert from "../../Alert/Alert"

import { transformHtmlWithLink } from "../../../lib/helpers";

const Toast = ({
  data,
  currentTime,
  ...rest
}) => {
  const { entityId, fieldAlertPriority, fieldAlertLink, fieldAlertText: { processed }, fieldAlertDismissedFor } = data;
  const variant = fieldAlertPriority === 1 ? 'danger' : fieldAlertPriority === 2 ? 'warning' : 'secondary';
  // remove <p> tags and replace &nbsp; with a space so it doesn't mess up word wrap
  const formattedAlertText = processed.replace(/<\/?p>/g, '').replace(/\u00a0/g, ' ');

  return (
    <Alert
      alertId={`alert-${entityId}`}
      currentTime={currentTime}
      dismissalExpiration={fieldAlertDismissedFor === null ? null : moment.utc().add(fieldAlertDismissedFor, 'hours')}
      variant={variant}
      {...rest}
    >
      <span className="font-weight-bold" sx={{ mr: "8px" }}>Important notice:</span>
      <span sx={{ mr: "8px" }}>{ReactHtmlParser(formattedAlertText, { transform: transformHtmlWithLink })}</span>
      {fieldAlertLink && (
        <Link href={fieldAlertLink.url.path} color="black">{fieldAlertLink.title || 'Read more'}</Link>
      )}
    </Alert>
  );
};

export default Toast;
