import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      drupal {
        node: nodeQuery(filter: {
          conditions: [
            { field: "type", value: "search_page", operator: EQUAL }
            { field: "status", value: "1", operator: EQUAL }
          ]
        }) {
          entities {
            entityUrl {
              path
            }
          }
        }
      }
    }
  `);

  return data?.drupal?.node?.entities[0]?.entityUrl?.path || '/search';
};
