import React, { Fragment } from 'react';
import './dot-loader.scss';

const DotLoader = () => {
  return (
    <span>
      <span className="loader__dot">.</span><span className="loader__dot">.</span><span className="loader__dot">.</span>
    </span>
  )
}

export default DotLoader;
