import React from 'react';
import { Icon } from '../../../common';

const LocationBarError = ({ onClick }) => {
  return (
    <button onClick={onClick.bind(null, false)} className="location-bar__error">
      <span>No results found</span>
      <Icon name="x" />
    </button>
  );
};

export default LocationBarError;
