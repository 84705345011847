import React from "react";
import ReactPlayer from "react-player";
import classNames from "classnames";

import { Box } from "rebass";

const Video = ({ entity: { entity: { fieldMediaOembedVideo } }, className, ...rest }) => {
  return (
    <Box className={classNames("react-player-wrapper", className)} {...rest}>
      <ReactPlayer
        className="react-player"
        url={fieldMediaOembedVideo}
        width="100%"
        height="100%"
        controls
      />
    </Box>
  );
};

export default Video;