export const sortItems = (items, { sortKeys, ascend = true }) => {
  const signMod = ascend ? 1 : -1;

  const getSortValue = (sortItem) => sortKeys.reduce((acc, sortKey) => acc[sortKey], sortItem);

  return [...items].sort((a, b) => {
    const sortValueA = getSortValue(a);
    const sortValueB = getSortValue(b);
    const formattedA = typeof sortValueA === 'string' ? sortValueA.toLowerCase() : sortValueA;
    const formattedB = typeof sortValueB === 'string' ? sortValueB.toLowerCase() : sortValueB;

    if (formattedA < formattedB) {
      return -1 * signMod;
    }

    if (formattedA > formattedB) {
      return 1 * signMod;
    }

    return 0;
  });
};
