import React, { createContext, useReducer, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import NavWrapper from './NavbarWrapper/NavbarWrapper';
import FooterQuery from './Footer/FooterQuery.jsx';
import FooterBase from './Footer/FooterBase.jsx';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import Seo from './Seo/Seo';
import GdprAlert from './GdprAlert/GdprAlert';
import { getMetaTransformations } from '../lib/helpers';
import Link from '../common/Link/Link';
import Logo from '../assets/logo/tabc-badge-lg.png';
import XIcon from '../assets/icons/x.svg';

const { getCoordsFromPosition, getCoordsFromAddress, metersToMiles, getLocation, getZip } =
  typeof window !== 'undefined' ? require('../services/geolocation') : {};

export const GlobalContext = createContext({});

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'UPDATE_ZIP':
      return { ...state, zip: payload };
    case 'UPDATE_ZIP_LOAD':
      return { ...state, zipLoad: payload };
    case 'UPDATE_RECOMMENDED_LOCATIONS':
      return { ...state, ...payload };
    case 'UPDATE_SELECTED_MENU_ITEMS':
      return { ...state, selectedMenuItems: payload };
    case 'UPDATE_NAV_DRAWER':
      return { ...state, navDrawerOpen: payload };
    default:
      return state;
  }
};

const Layout = ({
  children,
  pageContext: { breadcrumbs, url, metaTags, pagination = false, noIndexNoFollow = false },
  location,
}) => {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
    drupal: {
      homePage: {
        entities: [
          {
            fieldHomeMetadata: { entity: homeMetaData },
            fieldOfficeLink: {
              entity: {
                entityUrl: { path: officeLink },
              },
            },
          },
        ],
      },
      offices: { entities: offices },
    },
  } = useStaticQuery(graphql`
    query getOffices {
      site {
        siteMetadata {
          siteUrl
        }
      }
      drupal {
        homePage: nodeQuery(filter: { conditions: [{ field: "type", value: "home_page", operator: EQUAL }] }) {
          entities {
            ... on Drupal_NodeHomePage {
              fieldHomeMetadata {
                entity {
                  ... on Drupal_ParagraphMetadata {
                    fieldMetadataTitle
                    fieldMetadataDescription
                  }
                }
              }
              fieldOfficeLink {
                entity {
                  entityUrl {
                    path
                  }
                }
              }
            }
          }
        }
        offices: nodeQuery(
          filter: {
            conditions: [
              { field: "type", value: "office_page", operator: EQUAL }
              { field: "status", value: "1", operator: EQUAL }
            ]
          }
          limit: 1000
        ) {
          entities {
            ... on Drupal_NodeOfficePage {
              title
              fieldOfficeAddress {
                addressLine1
                addressLine2
                locality
                postalCode
              }
              fieldOfficeRegion {
                entity {
                  ... on Drupal_TaxonomyTermOfficeRegion {
                    name
                    description {
                      processed
                    }
                  }
                }
              }
              fieldPhoneNumber
              fieldFaxNumber
              fieldOfficeHours {
                processed
              }
              fieldOfficeServices {
                entity {
                  ... on Drupal_TaxonomyTermOfficeService {
                    tid
                    name
                  }
                }
              }
              fieldZipCodesServed
              fieldOfficeCategory {
                entity {
                  ... on Drupal_TaxonomyTermOfficeType {
                    tid
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const initialState = {
      zip: '',
      offices,
      recommendedLocations: { regional: {}, field: {} },
      selectedMenuItems: null,
      navDrawerOpen: false,
      zipLoad: false,
    },
    [state, dispatch] = useReducer(reducer, initialState),
    metaData = getMetaTransformations({ homeMetaData, location, metaTags, pagination });

  const getEligibleOffices = ({ zip }) =>
    offices.filter(({ fieldZipCodesServed }) => fieldZipCodesServed.search(`${zip}`) !== -1);

  const getNearestOffices = async ({ latitude, longitude, zip }) => {
    if (!latitude || !longitude) ({ latitude, longitude } = await getCoordsFromAddress(zip));

    const currentLocation = await getCoordsFromPosition(latitude, longitude),
      eligibleOffices = getEligibleOffices({ zip, offices });

    const recommendedLocations = await eligibleOffices.reduce(async (prom, office) => {
      const closest = await prom,
        {
          fieldOfficeCategory: {
            entity: { name: fieldOfficeType },
          },
          fieldOfficeAddress: { addressLine1, addressLine2, locality, postalCode },
        } = office,
        regional = fieldOfficeType === 'Regional Office',
        coords = await getCoordsFromAddress(`${addressLine1}, ${addressLine2}, ${locality}, ${postalCode}`),
        location = getCoordsFromPosition(coords.latitude, coords.longitude),
        distance = metersToMiles(
          window.google.maps.geometry.spherical.computeDistanceBetween(currentLocation, location)
        ),
        validRegionalConditions = [
          regional && !closest.regional.distance,
          regional && distance < closest.regional.distance,
        ],
        validFieldConditions = [
          regional.distance && distance < regional.distance,
          !regional.distance && !closest.field.distance,
          !regional.distance && closest.field.distance && distance < closest.field.distance,
        ];

      if (validRegionalConditions.some((c) => c)) {
        closest.regional.office = office;
        closest.regional.distance = distance;

        if (closest.field.distance && distance < closest.field.distance) {
          closest.field = {};
        }
      }

      if (!regional && validFieldConditions.some((c) => c)) {
        closest.field.office = office;
        closest.field.distance = distance;
      }

      return closest;
    }, Promise.resolve({ regional: {}, field: {} }));

    if (!Object.keys(recommendedLocations.regional).length) {
      return { error: true };
    }

    dispatch({ type: 'UPDATE_RECOMMENDED_LOCATIONS', payload: { zip, recommendedLocations } });
    return { recommendedLocations, zip };
  };

  const getUserLocation = async () => {
    let payload = {};

    try {
      const {
          coords: { longitude, latitude },
        } = await getLocation(),
        googleCoords = getCoordsFromPosition(latitude, longitude),
        zip = await getZip(googleCoords);

      if (!getEligibleOffices({ offices, zip }).length) {
        return (payload = { error: true });
      }

      payload = await getNearestOffices({ latitude, longitude, zip });
    } catch (e) {
      payload = { error: true };

      console.log(e);
    } finally {
      if (!state.zipLoad) dispatch({ type: 'UPDATE_ZIP_LOAD', payload: true });

      return payload;
    }
  };

  const zipError = (
    <>
      <span>No results found. </span>
      {officeLink && <Link href={officeLink}>View all locations.</Link>}
    </>
  );

  const context = {
    ...state,
    dispatch,
    getUserLocation,
    getNearestOffices,
    zipError,
    officeLink,
  };

  //  MODAL CODE SHOULD BE  REMOVED ON 12/1/20
  const [modal, setModal] = useLocalStorage('modal', true);

  return (
    <>
      <Seo
        base={siteUrl}
        canonical={url}
        search={location.search}
        breadcrumbs={breadcrumbs}
        metaTags={metaData}
        noIndexNoFollow={noIndexNoFollow}
      />
      <GlobalContext.Provider value={context}>
        <header>
          <a href="#content" className="sr-only sr-only-focusable">
            Skip to main content
          </a>
          <NavWrapper />
          {breadcrumbs?.length > 0 && <Breadcrumbs breadcrumbs={breadcrumbs} />}
        </header>
        <main id="content">{children}</main>
        <footer className="app-footer">
          <FooterQuery />
          <FooterBase />
        </footer>
        <GdprAlert />
      </GlobalContext.Provider>
    </>
  );
};

// MODAL CODE SHOULD BE  REMOVED ON 12/1/20
// https://usehooks.com/useLocalStorage/
// Hook
const isBrowser = typeof window !== `undefined`;

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      let item = null;
      if (isBrowser) {
        item = window.localStorage.getItem(key);
      }
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export default Layout;
