import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import TextTruncate from 'react-text-truncate';
import classnames from 'classnames';
import { Link, PageContainer, LeftContainer, MainContainer } from '../../common';
import ConditionalWrapper from '../Helpers/ConditionalWrapper';

const Breadcrumbs = ({ breadcrumbs, noContainers = false }) => (
  <ConditionalWrapper
    condition={!noContainers}
    wrapper={(children) => (
      <div className="breadcrumb-container">
        <MainContainer row>
          <LeftContainer />
          <PageContainer>{children}</PageContainer>
        </MainContainer>
      </div>
    )}
  >
    <Breadcrumb>
      {breadcrumbs.map(({ text, url }, i) => {
        const active = i === breadcrumbs.length - 1;
        return (
          <li className={classnames('breadcrumb-item', active ? 'active' : null)} key={`breadcrumb-${i}`}>
            {active && text.length > 50 ? (
              <TextTruncate line={1} element="span" truncateText="…" text={text} />
            ) : active ? (
              <span>{text}</span>
            ) : (
              <Link href={url}>{text}</Link>
            )}
          </li>
        );
      })}
    </Breadcrumb>
  </ConditionalWrapper>
);

export default Breadcrumbs;
