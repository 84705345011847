import { useStaticQuery, graphql } from 'gatsby';

export const usePDFReplace = (props) => {
  const data = useStaticQuery(
    graphql`
      query allFile {
        allFile {
          edges {
            node {
              localURL
              base
            }
          }
        }
      }
    `
  );

  const isoTag = props.match(/<a\s.*?href=["'](.*?)["']*?>(.*?)<\/a>/g);
  const isoAnchors = isoTag !== null ? isoTag.map((data) => data.match(/href="([^"]*)/)[1]) : props;
  const isoFile = isoTag !== null ? isoAnchors.map((iso) => iso.replace(/^.*[\\\/]/, '')) : props;
  const filterISO = isoTag !== null ? data.allFile.edges.filter((a) => isoFile.some((b) => a.node.base === b)) : props;

  if (isoTag !== null) {
    isoAnchors.forEach((iso) => {
      const isoEl = iso.replace(/^.*[\\\/]/, '');
      const matchedISO = filterISO.find((element) => {
        return element.node.base === isoEl;
      });
      props = matchedISO ? props.replace(iso, encodeURI(matchedISO.node.localURL)) : props;
    });
  } else {
    return props;
  }

  return props;
};
