import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { Button, Col, Row } from 'react-bootstrap';

import { Icon, Link } from '../../common';

const TakeoverMenuColumns = ({ selectedMenuItems, dispatch }) => {
  const primaryItems = selectedMenuItems[0].links;
  const navToggles = useRef(primaryItems.map(() => React.createRef()));

  const listVariant = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
    hidden: { opacity: 0 },
  };

  const itemVariant = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -200 },
  };

  return (
    <Row>
      <Col lg={5}>
        <ul className="list-unstyled">
          {primaryItems.map((primaryItem, i) => {
            const isActive = selectedMenuItems && primaryItem === selectedMenuItems[1];
            const navToggle = navToggles.current[i];

            return (
              <li key={primaryItem.label}>
                {primaryItem.links.length ? (
                  <Button
                    aria-expanded={isActive}
                    aria-controls="takeover-subnav"
                    className={`nav-toggle d-flex align-items-center justify-content-between ${
                      isActive ? 'active' : ''
                    }`}
                    href={primaryItem.url.path + '/'}
                    ref={navToggle}
                    variant="link"
                    onClick={(e) => {
                      const newItems = [selectedMenuItems[0], primaryItem];

                      e.preventDefault();
                      navToggle && navToggle.current.blur();
                      dispatch({ type: 'UPDATE_SELECTED_MENU_ITEMS', payload: newItems });
                    }}
                  >
                    <span style={{textAlign : 'left'}}>{primaryItem.label}</span>
                    <span className="link-indicator">
                      <Icon name="chevron-right" />
                    </span>
                  </Button>
                ) : (
                  <Link href={primaryItem.url.path} style={{textAlign : 'left'}} className="d-block">
                    {primaryItem.label}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </Col>
      <Col lg={7} id="takeover-subnav">
        {selectedMenuItems.length > 1 && (
          <motion.ul initial="hidden" animate="visible" variants={listVariant} className="list-unstyled pl-4">
            <li key="title">
              <span className="column-title">
                <Link href={selectedMenuItems[1].url.path}>{selectedMenuItems[1].label}</Link>
              </span>
            </li>
            {selectedMenuItems[1].links.map((menuItem, i) => (
              <motion.li variants={itemVariant} key={i} style={{ marginTop: '24px' }}>
                <Link href={menuItem.url.path}>{menuItem.label}</Link>
              </motion.li>
            ))}
          </motion.ul>
        )}
      </Col>
    </Row>
  );
};

export default TakeoverMenuColumns;
