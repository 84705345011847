const pageKey = 'page'

export const getMetaTransformations = ({
  homeMetaData,
  location: { pathname, search },
  metaTags,
  pagination
}) => {
  if (pathname === '/') return homeMetaData
  if (!metaTags) return

  const { fieldMetadataTitle } = metaTags

  if (pagination && search.search(pageKey) !== -1) {
    const params = new URLSearchParams(search)
      , page = params.get(pageKey)

    return {
      fieldMetadataTitle: `Results Page ${page} for ${fieldMetadataTitle}`,
      fieldMetadataDescription: null
    }
  }

  return metaTags
}