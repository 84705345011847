/** @jsx jsx */
import { jsx } from "theme-ui";

const Heading = ({ className, styledAs, as, color, ...rest }) => {
  let classes = className ? [className] : [];

  if (styledAs === "hero") {
    classes.push('h1');
    if (!color) classes.push('text-gold');
  } else {
    classes.push(styledAs);
  }

  if (color==="darkYellow" || color==="gold") {
    classes.push('text-gold');
  }

  if (color==='gray' || color==='grey' || color==='muted') {
    classes.push('text-muted');
  }

  const Component = as || "h2";

  return (
    <Component
      className={classes.join(' ').trim()}
      {...rest}
    />
  );
};

export default Heading;
