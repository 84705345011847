/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Container, Row } from "react-bootstrap";

const MainContainer = ({
  className = "",
  id = "",
  maxWidth = "",
  children,
  wrap,
  row,
  ...rest
}) => {
  // if children contain <PageContainer>, wrap them in <Row>
  React.Children.forEach(children, child => {
    if (child.type.name === "PageContainer") row = true;
  });

  const classes =
    className + (!row ? " d-flex" + (wrap ? " flex-wrap" : "") : "");

  return (
    <Container id={id} className={classes} {...rest}>
      {!row && <>{children}</>}
      {row && <Row>{children}</Row>}
    </Container>
  );
};

export default MainContainer;
