import moment from 'moment';

export const getMonth = (num) => {
  const months = [
    'Jan',
    'Feb',
    'March',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];

  return months[num];
};

export const formatDateMMMDYYYY = (dateString) => {
  const date = moment(dateString).toDate();
  return `${getMonth(date.getMonth())} ${date.getDate()}, ${date.getFullYear()}`
}

export const formatFormPacketDate = (timestamp) => {
  const date = new Date(timestamp * 1000);

  return `${getMonth(date.getMonth())} ${date.getFullYear()}`;
};

export const getCurrentTimeWithOffset = (timeZone = 'America/Chicago', locale = 'en-US') => {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
    timeZoneName: 'short',
    hour12: false,
  };

  return Intl.DateTimeFormat(locale, options).format(new Date());
};

export const generateDateFormats = (dateFormats, date) =>
  dateFormats.map((dateFormat) =>
    moment(date)
      .utc()
      .local()
      .format(dateFormat)
  );
