// Vendors
import React, { useState } from "react";
import { motion } from "framer-motion";
import classnames from "classnames";
import Button from "react-bootstrap/Button";

// Components
import Container from "../../common/Container/MainContainer/MainContainer";
import { Icon, Link } from "../../common";

// Context
import { NavbarContext } from "../NavbarWrapper/Navbar/Navbar";

// Styles
import "./SubNavMobile.scss";

const SubNavMobile = () => {
  const [slideDirection, setSlideDirection] = useState();
  return (
    <NavbarContext.Consumer>
      {context => {
        const { selectedMenuItems, dispatch, navItems } = context;
        const currentPageMenuItems = !!selectedMenuItems
          ? selectedMenuItems[selectedMenuItems.length - 1].links
          : navItems;
        const currentPageTitle = !!selectedMenuItems
          ? selectedMenuItems[selectedMenuItems.length - 1].label
          : null;

        const handleBackClick = () => {
          setSlideDirection("left");
          if (selectedMenuItems.length <= 1) {
            return dispatch({ type: "UPDATE_SELECTED_MENU_ITEMS", payload: null });
          }
          dispatch({ type: "UPDATE_SELECTED_MENU_ITEMS", payload: [selectedMenuItems[0]] });
        };
        return (
          <div
            className={classnames(
              "mobile-nav",
              !!selectedMenuItems ? "light" : "dark"
            )}
          >
            <Container>
              <motion.ul
                initial={{
                  x:
                    slideDirection === "right"
                      ? 1000
                      : slideDirection === "left"
                        ? -1000
                        : 0
                }}
                animate={{ x: 0 }}
                transition={{
                  type: "spring",
                  stiffness: 500,
                  damping: 250
                }}
                className="mobile-nav-ul"
                key={!!selectedMenuItems ? selectedMenuItems.length : 0}
              >
                {!!selectedMenuItems && (
                  <div className="mobile-nav-ul-heading">
                    <Button
                      className="ml-n2"
                      onClick={() => {
                        handleBackClick();
                      }}
                      style={{ display: "flex" }}
                      variant="link"
                    >
                      <Icon name="chevron-left" />
                      <span>Back</span>
                    </Button>
                    <Link
                      className="title"
                      href={
                        selectedMenuItems[selectedMenuItems.length - 1].url.path
                      }
                    >
                      {currentPageTitle}
                    </Link>
                  </div>
                )}
                {currentPageMenuItems.map((item, i) => (
                  <li key={i}>
                    {item.links ? (
                      <Button
                        href={item.url.path}
                        onClick={(e) => {
                          e.preventDefault();
                          setSlideDirection("right");

                          let existingItems = !!selectedMenuItems
                            ? selectedMenuItems
                            : [];
                          dispatch({ type: "UPDATE_SELECTED_MENU_ITEMS", payload: existingItems.concat(item) });
                        }}
                        variant="link"
                      >
                        {item.label}
                        {item.links && item.links.length > 0 && (
                          <Icon name="chevron-right" />
                        )}
                      </Button>
                    ) : (
                        <Link
                          href={item.url.path}
                        >
                          {item.label}
                        </Link>
                      )}
                  </li>
                ))}
              </motion.ul>
            </Container>
          </div>
        );
      }}
    </NavbarContext.Consumer>
  );
};

export default SubNavMobile;
