// Vendors
import React, {
  useContext,
  useState,
  useRef,
  useEffect
} from "react";
import { motion, AnimatePresence } from "framer-motion";

import { GlobalContext } from "../../components/Layout";

const toggle = (dispatch, isOpen) => dispatch({ type: 'UPDATE_NAV_DRAWER', payload: !isOpen });

export const DrawerToggle = ({ render }) => {
  const { navDrawerOpen, dispatch } = useContext(GlobalContext);
  return <div>{render(toggle.bind(null, dispatch, navDrawerOpen))}</div>;
};

export const DrawerOpen = ({ forceOpen, render }) => {
  const transitionRef = useRef();
  const [height, set] = useState(0);
  const { navDrawerOpen, dispatch } = useContext(GlobalContext);
  const openDrawer = forceOpen || navDrawerOpen;

  useEffect(() => {
    if (transitionRef.current) {
      setHeight(transitionRef.current.offsetHeight);
    }
  }, [render, toggle.bind(null, dispatch, navDrawerOpen)]);

  const setHeight = height => set(height);

  return (
    <AnimatePresence>
      {openDrawer && (
        <motion.nav
          key="drawer-section"
          initial={{ height: 0 }}
          animate={{ height: height }}
          exit={{ height: 0 }}
          style={{ overflow: "hidden" }}
          className="drawer"
          id="nav-drawer"
        >
          <div className="drawer-wrapper" ref={transitionRef}>
            {render(toggle.bind(null, dispatch, navDrawerOpen))}
          </div>
        </motion.nav>
      )}
    </AnimatePresence>
  );
};
