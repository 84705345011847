/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as moment from "moment";

import LocationBar from "./LocationBar/LocationBar";
import AlertToast from "./AlertToast/AlertToast";
import Navbar from "./Navbar/Navbar";

const NavWrapper = () => {
  const data = useStaticQuery(graphql`
    query {
      drupal {
        alertToasts: nodeQuery(
          limit: 1000,
          filter: { 
            conditions: [
              { field: "type", value: "alert_toast", operator: EQUAL },
              { field: "status", value: "1", operator: EQUAL }
            ]
          }
        ) {
          entities {
            ... on Drupal_NodeAlertToast {
              fieldAlertToast {
                entity {
                  ... on Drupal_ParagraphAlertToast {
                    entityId
                    fieldAlertPriority
                    fieldAlertText {
                      processed
                    }
                    fieldAlertActivatedOn {
                      value
                    }
                    fieldAlertDeactivatedOn {
                      value
                    }
                    fieldAlertLink {
                      url {
                        path
                      }
                      title
                    }
                    fieldAlertDismissedFor
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const now = moment.utc();
  const alertToastsData = data.drupal.alertToasts?.entities[0]?.fieldAlertToast || [];
  const filteredAlerts = alertToastsData.filter(({ entity: { fieldAlertActivatedOn, fieldAlertDeactivatedOn } }) => {
    const activationDate = moment.utc(fieldAlertActivatedOn.value);
    const deactivationDate = moment.utc(fieldAlertDeactivatedOn.value);

    return now.isBetween(activationDate, deactivationDate);
  }).slice(0, 3);

  return (
    <>
      <Navbar />
      <LocationBar />
      {filteredAlerts.map(({ entity }) => (
        <AlertToast key={entity.fieldAlertText.processed} data={entity} currentTime={now} />
      ))}
    </>
  );
};

export default NavWrapper;