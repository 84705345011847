import React, { useState, useContext, useRef, useEffect } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import Submit from '../../Form/Submit';
import LocationBarError from './LocationBarError';
import useClickOutside from '../../../hooks/useClickOutside';
import { GlobalContext } from '../../Layout';
import { Icon } from '../../../common';
import './LocationBarForm.scss';

const LocationBarForm = ({ setShowInput, userLocationError, clearUserLocationError }) => {
  const { getNearestOffices } = useContext(GlobalContext),
    [formStatus, updateFormStatus] = useState('new'),
    [zipInput, updateZipInput] = useState(''),
    [error, updateError] = useState(null),
    formRef = useRef(null),
    zipInputRef = useRef(null);

  useClickOutside({ ref: formRef, onClickOutside: () => setShowInput(false) });

  useEffect(() => {
    zipInputRef?.current && zipInputRef.current.focus();
  }, []);

  const onChangeInput = ({ target: { value } }) => {
    if (value.length > 5) return;

    if (value === '' || /^[0-9]+$/.test(value)) updateZipInput(value);
  };

  const isInvalid = () => {
    if (error) updateError(false);

    const invalidConditions = [!zipInput];

    return invalidConditions.includes(true);
  };

  const resetForm = (showError = false) => {
    updateError(showError);

    updateZipInput('');
    updateFormStatus('new');

    zipInputRef?.current && zipInputRef.current.focus();
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isInvalid()) return updateError(true);

    updateFormStatus('submitting');

    try {
      const nearestOffices = await getNearestOffices({ zip: zipInput });

      if (nearestOffices?.error) {
        return resetForm(true);
      }
    } catch (e) {
      return resetForm(true);
    }

    resetForm();
    setShowInput(false);
  };

  const handleClearUserLocationError = () => {
    clearUserLocationError();
    resetForm();
  };

  return (
    <Form ref={formRef} className="location-bar__form fadeInRight" onSubmit={onSubmit}>
      <InputGroup className="location-bar-form-input">
        <div className="form-control-wrapper">
          <Form.Control
            aria-label="TX ZIP code"
            className={`location-bar__input ${error && 'has-error'}`}
            id="location-bar-zipcode"
            name="zipcode"
            onChange={onChangeInput}
            placeholder="Enter a TX ZIP code"
            size="sm"
            type="text" // number type won't trigger onchange event when typing periods, keep as text
            value={zipInput}
            ref={zipInputRef}
          />
          {error && <LocationBarError onClick={resetForm} />}
          {userLocationError && <LocationBarError onClick={handleClearUserLocationError} />}
          <Button
            aria-label="Clear"
            disabled={!zipInput}
            onClick={() => resetForm()}
            size="sm"
            type="reset"
            variant="clear"
          >
            <Icon name="x" />
          </Button>
        </div>
        <InputGroup.Append>
          <Submit
            className="location-bar__submit"
            disabled={formStatus !== 'new' || zipInput.length < 5}
            size="sm"
            status={formStatus}
            type="submit"
            variant="dark-blue"
          >
            Submit
          </Submit>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  );
};

export default LocationBarForm;
