import React, { useState, useRef, useEffect } from "react";
import { navigate } from "gatsby";

import useSearchPath from "../../hooks/useSearchPath";

import SearchMobile from "./ExpandableSearchMobile";
import SearchDesktop from "./ExpandableSearchDesktop";

import "./ExpandableSearch.scss";

const ExpandableSearch = ({ open, dimensions, setShowSearchField, size }) => {
  const node = useRef();
  const searchPath = useSearchPath();
  const [searchText, setSearchText] = useState("");

  const handleResize = e => {
    setShowSearchField(false);
  };

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    // outside click
    setShowSearchField(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    search(searchText);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
   // window.addEventListener("resize", handleResize);
    return () => {
    document.removeEventListener("mousedown", handleClick);
     // window.removeEventListener("resize", handleResize);
    };
  });

  const search = (searchText) => {
    const urlParams = new URLSearchParams();

    urlParams.set("query", searchText);
    navigate(`${searchPath}/?${urlParams.toString()}`);
    setShowSearchField(false);
    reset();
  };

  const reset = () => {
    setSearchText('')
  }

  const props = { open, setShowSearchField, searchText, setSearchText, search, reset, handleSubmit };

  return (
    <div ref={node} className="nav-middle-search">
      <SearchMobile {...props} />
      <SearchDesktop {...props} />
    </div>
  );
};

export default ExpandableSearch;
