import { useEffect } from 'react';

export default ({ ref, onClickOutside }) => {
  useEffect(() => {
    const onClick = (e) => {
      if (ref && !ref.current.contains(e.target)) onClickOutside();
    };

    document.addEventListener('mousedown', onClick);

    return () => document.removeEventListener('mousedown', onClick);
  }, []);
};
