import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Icon } from '../../common';
import { Container, Row, Col } from 'react-bootstrap';

const FooterBase = () => {
  const data = useStaticQuery(graphql`
    query siteQuery {
      site {
        siteMetadata {
          social {
            facebook
            twitter
            youtube
          }
        }
      }
    }
    #
  `);

  const { facebook, twitter, youtube } = data.site.siteMetadata.social;
  const year = new Date().getFullYear();

  return (
    <footer className="footer-base bg-dark-blue text-white py-4 py-lg-3">
      <Container>
        <Row className="align-items-center">
          <Col lg={9} className="mb-6 mb-lg-0">
            <p className="text-small font-weight-medium m-0">
              Copyright &copy;{year} Texas Alcoholic Beverage Commission. All rights reserved.
            </p>
          </Col>
          <Col lg={3} className="social text-lg-right">
            <a href={facebook} target="_blank" rel="noopener" aria-label="TABC Facebook">
              <Icon name="facebook-square" />
            </a>
            <a href={twitter} target="_blank" rel="noopener" aria-label="TABC Twitter">
              <Icon name="twitter-square" />
            </a>
            <a href={youtube} target="_blank" rel="noopener" aria-label="TABC YouTube">
              <Icon name="youtube-square" />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default FooterBase;
