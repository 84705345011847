import React from 'react';
import { Accordion, Link } from '../../common';

const FooterMobile = ({ footerItems }) => {
  return (
    <nav className="footer-mobile">
      <Accordion
        container
        type="footer"
        accordionItems={footerItems.map(({ label, links }) => ({
          header: label,
          content: (
            <ul className="list-unstyled">
              {links.map(({ url: { path }, label }, i) => (
                <li key={i}>
                  <Link href={path}>{label}</Link>
                </li>
              ))}
            </ul>
          ),
        }))}
      />
    </nav>
  );
};

export default FooterMobile;
